/*
    Components: Pictures
    ---
    Pictures are layed on top of each other
*/

$picture-gutter: 5px;

.c-pictures {
    position: relative;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.c-picture {
    position: relative;
    width: 100%;
    background-color: palette(color-neutral,xxx-light);
    box-shadow: 0 0 0 $picture-gutter rgba(palette(color-neutral,xxx-light),1), 0 10px 50px rgba(palette(color-neutral,xxx-dark),.125);
}

    .c-picture__image {
        width: 100%;
        max-width: auto;
        position: relative;
        opacity: 0;
        transition: opacity $animation-duration-beta $animation-easing-alpha;

        &.is-loaded {
            opacity: 1;
        }
    }

.c-pictures--first {
    justify-content: center;

    .c-picture:nth-child(1) {
        z-index: 30;
        transform: rotate(-2deg);

        max-width: 50%;
        top: 10px;

        @include mq(bp4) {
            max-width: 40%;
            top: 25px;
            right: -20px;
        }
    }

    .c-picture:nth-child(2) {
        z-index: 20;
        transform: rotate(1deg);
        max-width: 50%;

        @include mq(bp4) {
            max-width: 40%;
            z-index: 20;
            left: -20px;
        }
    }

    .c-picture:nth-child(3) {
        z-index: 50;
        transform: rotate(-1deg);
        max-width: 90%;
        margin-top: -20px;

        @include mq(bp4) {
            max-width: 60%;
            margin-top: -200px;
        }
    }
}

.c-pictures--second {
    justify-content: center;

    .c-picture:nth-child(1) {
        z-index: 30;
        top: 20px;
        max-width: 55%;

        @include mq(bp4) {
            max-width: 55%;
            top: 50px;
            right: -20px;
        }

        @include mq(bp5) {
            top: 100px;
        }
    }

    .c-picture:nth-child(2) {
        z-index: 20;
        transform: rotate(1deg);
        width: 35%;
    }

    .c-picture:nth-child(3) {
        z-index: 50;
        transform: rotate(2deg);

        width: 55%;
        margin-top: -30px;

        @include mq(bp3) {
            margin-top: -100px;
        }

        @include mq(bp4) {
            width: 55%;
            left: -20px;
            margin-top: -100px;
        }

        @include mq(bp5) {
            margin-top: -200px;
        }
    }
}

.c-pictures--last {
    justify-content: center;

    .c-picture:nth-child(1) {
        z-index: 50;
        transform: rotate(-1deg);
        max-width: 90%;
        //margin-top: -20px;

        @include mq(bp4) {
            max-width: 60%;
            //margin-top: -200px;
        }
    }
}

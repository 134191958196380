/*
    Components: List
    ---
    All sorts of lists.
    Replacements for default bullet and numbered lists.
*/

/**
 *  1. Use the counter as content
 *
 *  2. Increment the counter by 1
 */

// Custom bulleted list

.c-list-bullet {
    //@include margin-tb(0,1);

    li > & {
        margin: 0;
        @include padding-lr(1,0);
    }
}

    .c-list-bullet__item {
        position: relative;
        @include padding-lr(1,0);

        &:before {
            content: '\2022';
            position: absolute;
            top: 0;
            left: 0;
            color: palette(color-gamma);

            li > ul > & {
                content: '\203A';
            }
        }
    }

// Custom numbered list

.c-list-number {
    counter-reset: li;
    @include margin-tb(0,1);

    li > & {
        margin: 0;
        @include padding-lr(1,0);
    }
}

    .c-list-number__item {
        position: relative;
        @include padding-lr(1,0);

        &:before {
            content: counter(li); /* [1] */
            counter-increment: li; /* [2] */
            position: absolute;
            top: 0;
            left: 0;
            font-size: 80%;
            font-weight: bold;
            color: palette(color-neutral, base);
       }
    }

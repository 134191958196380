/*
    Components: Brand
    ---
    Brand image/logo
*/

$logo-ratio:    3.35;
$logo-height:   $alpha-leading * 1.25rem;
$logo-width:    $logo-height * $logo-ratio;

.c-logo {
    width: $logo-width;
    height: $logo-height;
    //display: block;
    //display: inline-flex;
    //align-items: center;

    .c-header & {
        margin-bottom: 0;
    }
}

    .c-logo__svg {
        display: block;
    }

    .c-logo__link {
        display: block;
    }

    .c-logo__path {
        fill: palette(color-gamma,x-dark);

        .c-logo--neg & {
            fill: palette(color-neutral,xxx-light);
        }
    }

    .c-logo__dot {
        fill: palette(color-alpha,dark);

        //.c-logo--neg & {
        //    fill: palette(color-neutral,xxx-light);
        //}
    }

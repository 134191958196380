//
//  Tools: Spacing
//  ---
//  Mixins to add padding and margin in rem with $px-fallback
//

// Horizontal spacing

// horizontal padding based on the alpha font size in rem
// use: @include padding-lr(#left, #right);

@mixin padding-lr($left: $columns-gutter, $right: $left) {
    padding-left: $left * 1rem;
    padding-right: $right * 1rem;
}

@mixin padding-left($left: $columns-gutter) {
    padding-left: $left * 1rem;
}

@mixin padding-right($right: $columns-gutter) {
    padding-right: $right * 1rem;
}

// horizontal margin based on the alpha font size in rem
// use: @include margin-lr(#left, #right);

@mixin margin-lr($left: $columns-gutter, $right: $left) {
    margin-left: $left * 1rem;
    margin-right: $right * 1rem;
}

@mixin margin-left($left: $columns-gutter) {
    margin-left: $left * 1rem;
}

@mixin margin-right($right: 1) {
    margin-right: $right * 1rem;
}

// Vertical spacing

// vertical padding based on the alpha font size in rem
// use: @include padding-tb(#top, #bottom);

@mixin padding-tb($top: $alpha-leading, $bottom: 0) {
    padding-top: $top * 1rem;
    padding-bottom: $bottom * 1rem;
}

@mixin padding-bottom($bottom: $alpha-leading) {
    padding-bottom: $bottom * 1rem;
}

@mixin padding-top($top: $alpha-leading) {
    padding-top: $top * 1rem;
}

// vertical margin based on the alpha font size in rem
// use: @include margin-tb(#top, #bottom);

@mixin margin-tb($top: $alpha-leading, $bottom: 0) {
    margin-top: $top * 1rem;
    margin-bottom: $bottom * 1rem;
}

@mixin margin-bottom($bottom: $alpha-leading) {
    margin-bottom: $bottom * 1rem;
}

@mixin margin-top($top: 1) {
    margin-top: $top * 1rem;
}

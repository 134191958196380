/*
    Shame
    ---
    Hacks and quick fixes
*/

.o-container {
    position: relative;
    width: 100%;
}

.s-content {
    h3 {
        color: palette(color-neutral,xxx-dark);
    }

    a {
        text-decoration: underline;
    }
    //ul {
    //    padding-left: 0;
    //}
}

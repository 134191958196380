/*
    Objects: Tables
    ---
    Responsive table object
*/

.o-table-responsive {
    > thead {
        display: none; // hide header on mobile
    }

    > tbody > tr > th {
        display: block;
        width: 100%;
    }

    > tbody > tr > td {
        display: block; // stack cells

        &:before {
            content: attr(headers)': '; // move header title contents next to cell content
        }
    }

    @include mq(bp2) {
        > thead {
            display: table-header-group; // default table layout
        }

        > tbody > tr > td,
        > tbody > tr > th {
            display: table-cell; // default table layout
        }

        > tbody > tr > th {
            width: initial; // reset back to initial width
        }

        > tbody > tr > td:before {
            display: none; // hide header title contents
        }
    }
}

/*
    Utilities: Layers
    ---
*/

// Loop through the z-indexes map and get the value.
@each $z-index, $value in $z-indexes {

    // Z-index utility class (e.g. .u-z-alpha)
    .u-z-#{$z-index} {
        z-index: $value;
    }

}

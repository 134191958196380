/*
    Components: Page
    ---
    Big page chunks
*/

.c-page {
    //display: flex;
    background-image: linear-gradient(to bottom, palette(color-alpha,light), palette(color-beta,light), palette(color-delta,x-light), palette(color-gamma,light), palette(color-alpha,light));
}

.c-page-header {
    //position: absolute;
    //position: fixed;
    //top: $alpha-leading * 1rem;
    //right: 0;
    ////bottom: 0;
    //left: 0;
    //background-color: rgba(white,.975);
    //border-radius: 0 $radius $radius 0;
    //box-shadow: 0 10px 30px rgba(palette(color-neutral,xxx-dark),.125);
    //z-index: 2;
    //@include padding-left($columns-gutter);

    //@include mq(bp3) {
    //    position: absolute;
    //    top: 0;
    //    right: 0;
    //    //bottom: 0;
    //    left: 0;
    //}
}

.c-page-main {
    z-index: 1;
    position: relative;
    //flex-basis: 80%;
    //@include padding-top(5.5);
}

.c-page-top-cta {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 50;
    @include padding-tb($alpha-leading,0);
    @include padding-lr($columns-gutter,$columns-gutter);

    .c-button {
        box-shadow: 0 5px 15px rgba(palette(color-neutral,xxx-dark),.25);
    }
}

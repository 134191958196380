//
//  Tools: Mixins
//  ---
//  Global mixins
//

// Calculate text-color based on background-color

@mixin text-color($c1) {
    // Calculations based on https://vimeo.com/60224584
    @if (.2126*red($c1) + .7152*green($c1) + .0722*blue($c1))/255 <= .5 {
        color: mix(#FFF, $c1, 95%);
    } @else {
        color: mix(#000, $c1, 70%);
    }
}

// UI mixins.

// Cross browser background opacity.

@mixin background-opacity($color, $alpha) {
    $rgba: rgba($color, $alpha);
    $ie-hex-str: ie-hex-str($rgba);
    background-color: $rgba;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
    zoom: 1;
}

//
// Media Query mixin
// @include mq(#point, #query1, #query2);
//

@mixin mq($breakpoint, $query1: min, $query2: width) {
    $query: map-get($breakpoints, $breakpoint);

    @if $query != null {
        @media (#{$query1}-#{$query2}: #{$query}) {
            @content;
        }
    } @else {
        @media (#{$query1}-#{$query2}: $breakpoint) {
            @content;
        }
    }
}

// Vertically center any object within its container. Works in IE9 and up.
// @include vertical-center;

@mixin vertical-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// Clearfix.
// @mixin clearfix

@mixin clearfix {
    *zoom: 1;

    &:before,
    &:after {
        display: table;
        content: '';
    }

    &:after {
        clear: both;
    }
}

// Prevent Long URL’s From Breaking Out of Container.
// http://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container

@mixin word-break {
    word-break: break-all;
    hyphens: auto;
}

@mixin hide-text {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

/*
    Utilities: Print
    ---
    Print stylesheet
*/

@media print {

    // ---
    // Reset
    // ---

    * {
        background: transparent !important;
        color: palette(color-neutral,x-dark) !important;
        box-shadow: none !important;
        text-shadow: none !important;
        filter: none !important;
    }

    h2,
    h3 {
        orphans: 3;
        widows: 3;
        page-break-after: avoid;
    }

    p {
        orphans: 3;
        widows: 3;
    }

    pre,
    blockquote {
        border: 1px solid palette(color-neutral,light);
        page-break-inside: avoid;
    }

    abbr[title]:after {
        content: ' (' attr(title) ')';
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: ' (' attr(href) ')';
    }

    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: '';
    }

    img {
        max-width: 100% !important;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr {
        page-break-inside: avoid;
    }

    // ---
    // Utility classes
    // ---

    .noprint {
        display: hidden;
    }
}

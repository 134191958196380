/*
    Components: Timeline
    ---
    The timeline on the second section
*/

.c-timeline {
    position: relative;
}

    .c-timeline__intro {
        @include mq(bp4) {
            position: absolute;
            max-width: 40%;
            top: -.75rem;
            left: 0;
            z-index: 2;
        }
    }

    .c-timeline__item {
        position: relative;
        @include padding-bottom($alpha-leading);

        &:before {
            content: '';
            display: block;
            width: 1px;
            position: absolute;
            background-color: rgba(palette(color-neutral,xxx-dark),.1);
            top: 0;
            bottom: 0;

            @include mq(bp4) {
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &:first-of-type {
            &:before {
                top: 10px;
            }
        }

        &:last-of-type {
            padding-bottom: 0;

            &:before {
                //top: 10px;
                bottom: auto;
                height: 10px;
            }
        }


        @include mq(bp4) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;

            //& + & {
            //    @include padding-top($alpha-leading);
            //}
        }
    }

    .c-timeline__title {
        color: rgba(palette(color-neutral,xxx-dark),.95);
        @include text-dimensions($ms1,1);
    }

    .c-timeline__time {
        display: flex;
        align-items: center;
        align-self: flex-start;
        position: relative;
        //color: rgba(palette(color-neutral,xxx-dark),.4);
        margin-bottom: 0;
        font-weight: $gamma-font-weight;
        //color: palette(color-gamma,dark);
        @include padding-lr($columns-gutter);
        @include text-dimensions($ms-1,1);

        &:before {
            content: '';
            display: block;
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 20px;
            border: 3px solid palette(color-neutral,xxx-light);
            background-color: palette(color-gamma);
            box-shadow: 0 0 0 1px rgba(palette(color-neutral,xxx-dark),.15), 0 0 6px rgba(palette(color-neutral,xxx-dark),.15);
            //box-shadow: 0 0 6px rgba(palette(color-neutral,xxx-dark),.15);
            //box-shadow: 0 0 0 1px rgba(palette(color-neutral,xxx-dark),.1);
            left: -7px;
        }

        @include mq(bp4) {
            width: 50%;
            text-align: right;
            justify-content: flex-end;
            position: absolute;
            top: 0;
            left: 0;
            @include padding-lr($columns-gutter);

            &:before {
                left: auto;
                right: -7px;
            }
        }
    }

    .c-timeline__body {
        @include padding-lr($columns-gutter);

        @include mq(bp4) {
            width: 50%;
        }
    }

    .c-timeline__aside {
        display: flex;
        //align-items: flex-end;
        flex-direction: column;
        justify-content: flex-end;
        //flex-wrap: wrap;
        @include padding-lr($columns-gutter);

        @include mq(bp4) {
            width: 50%;
            //max-width: 20em;
            @include padding-top($alpha-leading * 2);
        }
    }

    .c-timeline__info {
        width: 100%;
        //opacity: .5;
        color: rgba(palette(color-neutral,xxx-dark),.4);
        //@include text-dimensions($ms-1,1);
        @include ms-1;

        @include mq(bp4) {
            text-align: right;
        }
    }

/*
    Utilities: Colors
    ---
    Foreground & background color utilities
*/

// Loop through the different colors in palettes map
@each $palette, $value in $palettes {

    // Loop through the different shades in colors map
    @each $shade, $i in $value {

        // Colors (e.g .u-color-alpha-base)
        .u-#{$palette}-#{$shade} {
            color: $i;
        }

        // Background colors (e.g .u-bgcolor-alpha-base)
        .u-bg#{$palette}-#{$shade} {
            background-color: $i;
        }

    }
}

/*
    Elements: Images
    ---
    Default markup for images
*/

// Fix a > img problem

img {
    max-width: $img-max-width;
    vertical-align: top;
    border: $img-border;
}

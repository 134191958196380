/*
    Utilities: Spacing
    ---
    Utility classes for spacing
*/

// Loop through the increments map and get the value.
@each $increment, $value in $increments {

    // Increment utility class (e.g. .u-mt-alpha)
    .u-mt-#{$increment} {
        @include margin-top($value);
    }

    // Increment utility class (e.g. .u-mb-alpha)
    .u-mb-#{$increment} {
        @include margin-bottom($value);
    }

    // Increment utility class (e.g. .u-pt-alpha)
    .u-pt-#{$increment} {
        @include padding-top($value);
    }

    // Increment utility class (e.g. .u-pb-alpha)
    .u-pb-#{$increment} {
        @include padding-bottom($value);
    }
}

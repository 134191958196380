/*
    Utilities: Max widths
*/

.u-max-width-sm {
    max-width: 36em;
}

.u-max-width-md {
    max-width: 42em;
}

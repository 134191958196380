/*
    Utilities: Pull
    ---
    Pull elements
*/

// Loop through the different columns
@each $column in $columns {
    // Generate the pull classes
    @each $denominator, $numerator in $column {
        // Begin creating a numberator for our fraction up until we hit the
        // denominator.
        @for $numerator from 1 through $denominator {
            // Build a class in the format `.u-4-of-6`
            .u-pull-#{$numerator}-of-#{$denominator} {
                right: ($numerator / $denominator) * 100% !important;
                position: relative;
            }
        }
    }
}

// Loop through the different breakpoints
@each $breakpoint, $value in $breakpoints {
    @media (min-width: $value) {
        // Loop through the different columns
        @each $column in $columns {
            // Generate the pull classes
            @each $denominator, $numerator in $column {
                // Begin creating a numberator for our fraction up until we hit the
                // denominator.
                @for $numerator from 1 through $denominator {
                    // Build a class in the format `.u-4-of-6`
                    .u-pull-#{$numerator}-of-#{$denominator}-#{$breakpoint} {
                        right: ($numerator / $denominator) * 100% !important;
                        position: relative;
                    }
                }
            }
        }
    }
}

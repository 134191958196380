/*
    Components: Row
    ---
    Creates a horizontal row with padding
*/

.c-row {
    position: relative;
    @include padding-lr($columns-gutter,$columns-gutter);

    @include mq(bp3) {
        @include padding-lr($columns-gutter*4,$columns-gutter*4);

        & + & {
            @include padding-top($alpha-leading * 2);
        }
    }

    .c-row {
        padding-left: 0;
        padding-right: 0;
    }
}

.c-row--header {
    padding-bottom: 0;
    @include padding-top($alpha-leading * 2);
    @include padding-lr($columns-gutter,$columns-gutter);

    @include mq(bp3) {
        @include padding-tb($alpha-leading * 2, 0);
    }
}

.c-row--lg {
    & + & {
        @include mq(bp3) {
            @include padding-top($alpha-leading * 3);
        }
    }
}

.c-row--lg-top {
    @include padding-top($alpha-leading * 2);

    @include mq(bp3) {
        @include padding-top($alpha-leading * 3);
    }
}

.c-row--lg-bottom {
    @include padding-bottom($alpha-leading);

    @include mq(bp3) {
        @include padding-bottom($alpha-leading * 2);
    }
}

.c-row--xl {
    & + & {
        @include mq(bp3) {
            @include padding-top($alpha-leading * 4);
        }

        @include mq(bp6) {
            @include padding-top($alpha-leading * 6);
        }
    }
}

.c-row--xl-bottom {
    @include padding-bottom($alpha-leading);

    @include mq(bp3) {
        @include padding-bottom($alpha-leading * 3);
    }

    @include mq(bp6) {
        @include padding-bottom($alpha-leading * 5);
    }
}

.c-row--xl-top {
    @include padding-top($alpha-leading * 2);

    @include mq(bp3) {
        @include padding-top($alpha-leading * 4);
    }

    @include mq(bp6) {
        @include padding-top($alpha-leading * 6);
    }
}

.c-row--xxl {
    & + & {
        @include padding-top($alpha-leading);

        @include mq(bp3) {
            @include padding-top($alpha-leading * 6);
        }

        @include mq(bp6) {
            @include padding-top($alpha-leading * 10);
        }
    }
}

.c-row--xxl-top {
    @include padding-top($alpha-leading * 2);

    @include mq(bp3) {
        @include padding-top($alpha-leading * 6);
    }

    @include mq(bp6) {
        @include padding-top($alpha-leading * 10);
    }
}

.c-row--xxl-bottom {
    @include padding-bottom($alpha-leading);

    @include mq(bp3) {
        @include padding-bottom($alpha-leading * 5);
    }

    @include mq(bp6) {
        @include padding-bottom($alpha-leading * 9);
    }
}

.c-row--empty {
    padding: 0;
}

.c-row--clear-tb {
    padding-top: 0;
    padding-bottom: 0;
}

.c-row--clear-top {
    padding-top: 0;
}

    .c-row__section {
        & + & {
            //@include padding-top($alpha-leading);

            @include mq(bp3) {
                @include padding-top($alpha-leading * 2);
            }
        }
    }

    .c-row__section--xxl {
        & + & {
            @include mq(bp3) {
                @include padding-top($alpha-leading * 5);
            }

            @include mq(bp6) {
                @include padding-top($alpha-leading * 9);
            }
        }
    }

.c-row--cta {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
    Components: Background image
    ---
    Full width/height background images
*/

.c-background-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all $animation-duration-alpha $animation-easing-alpha;
}

.c-background-image--opacity-alpha {
    &.is-loaded {
        opacity: .5;
    }
}

.c-background-image--overlay {
    mix-blend-mode: screen;
}

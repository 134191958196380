/*
    Objects: Media embed
    ---
    Responsive media embedding object, for use with iframes
*/

.o-media-embed {
    width: 100%;
    height: 0;
    padding-bottom: $media-embed-ratio;
    position: relative;
    overflow: hidden;
    @include margin-bottom($media-embed-margin-bottom);

    > iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
